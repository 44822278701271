import { parse as parseCookie } from "cookie-es";
import { handleErrorWithSentry, replayIntegration } from "@sentry/sveltekit";
import * as Sentry from "@sentry/sveltekit";

Sentry.init({
	dsn: "https://9fb265b3e3baa9b90b4dd639b4829c33@o547857.ingest.us.sentry.io/4507341812727808",
	tracesSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [replayIntegration()],

	environment: import.meta.env.PROD ? "production" : "development",
	debug: false,
	release: `homecreationsweb@${import.meta.env.COMMIT_HASH}`,
});

Sentry.setUser({
	id: parseCookie(document.cookie).anonUserId,
	ip_address: "{{auto}}",
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
