import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/about": [3],
		"/collections": [~4],
		"/collections/[id]": [~5],
		"/dev": [6],
		"/inquire": [~7],
		"/inquire/success": [8],
		"/listings": [~9],
		"/listings/[id]": [~10],
		"/locations": [~11],
		"/locations/[id]": [~12],
		"/login": [13],
		"/plans": [~14],
		"/plans/[id]": [~15],
		"/util/cp": [16],
		"/util/icheck": [17],
		"/util/qr": [18]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';